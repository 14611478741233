import { Typography } from "@mui/material"
import {
	GetCurrentUpdateStatusDocument,
	MantovaFirmwareVersion,
	UpdateProgress,
	useGetCurrentFirmwareVersionQuery,
	useGetCurrentUpdateStatusQuery,
	useInterfaceNameQuery,
	useUpdateMantovaFirmwareMutation,
} from "generated/graphql"
import { useConfirm } from "providers/ConfirmProvider"
import { useMessage } from "providers/MessageProvider"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import HeatPumpUpdateSelector from "components/HeatPumpUpdateSelector"
import appendErrorMessage from "tools/appendErrorMessage"

type Props = {
	interfaceId: string
}
export default function HeatPumpSingleUpdate({ interfaceId }: Props) {
	const { t } = useTranslation(["pvtHeatPumpAdminPage", "heatPumpErrorCard"])
	const message = useMessage()
	const confirm = useConfirm()

	const [updateHeatPump] = useUpdateMantovaFirmwareMutation({
		refetchQueries: [{ query: GetCurrentUpdateStatusDocument, variables: { interfaceId: interfaceId } }],
	})

	const {
		data: currentFirmware,
		loading: currentFirmwareLoading,
		error: currentFirmwareError,
		refetch: refetchCurrentFirmware,
	} = useGetCurrentFirmwareVersionQuery({
		variables: { interfaceId: interfaceId },
	})

	const { data: interfaceName } = useInterfaceNameQuery({
		variables: { interfaceId: interfaceId },
	})

	const { data: currentUpdateStatus } = useGetCurrentUpdateStatusQuery({
		variables: { interfaceId: interfaceId },
	})

	useEffect(() => {
		if (currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.Completed) {
			refetchCurrentFirmware()
		}
	}, [currentUpdateStatus])

	const handleUpdateHeatPump = async (update: MantovaFirmwareVersion) => {
		await confirm(
			t("MsgConfirmHeatPumpUpdate", {
				interfaceName: interfaceName?.interface?.name ?? interfaceId,
				firmwareVersionName: `V${update.version.toFixed(1)}`,
			}),
		)

		try {
			await updateHeatPump({
				variables: { interfaceIds: interfaceId, firmwareId: update.id },
			})

			message.success(t("HeatPumpUpdateStarted"))
		} catch (e) {
			message.error(appendErrorMessage(t("Errors.UpdatingFirmware"), e))
		}
	}

	if (currentFirmwareError) {
		if (currentUpdateStatus?.interface?.updateStatus?.progress === UpdateProgress.Completed) {
			return (
				<Typography color="error" gutterBottom>
					{t("Errors.GettingCurrentFirmwareVersion")}
				</Typography>
			)
		}

		return (
			<Typography color="primary" gutterBottom>
				{t("FirmwareUpdating")}
			</Typography>
		)
	}

	return (
		<HeatPumpUpdateSelector
			currentUpdateStatus={currentUpdateStatus}
			handleUpdateHeatPump={handleUpdateHeatPump}
			currentFirmware={currentFirmware}
			loading={currentFirmwareLoading}
		/>
	)
}

import { CocType } from "generated/graphql"

const REFRESH_TOKEN = "refreshToken"
const ACCESS_TOKEN = "accessToken"

export type Claims = {
	id: string
	email: string
	name: string
	roles: string[]
}

export type NewUser = {
	email: string
	password: string
	firstName: string
	lastName: string
	isOrganization: boolean
	organizationName?: string
	cocNumber?: string
	cocType?: CocType
}

export type ResetPassword = {
	email: string
	password: string
}

export type ForgotPassword = {
	email: string
}

export type RequestAccount = {
	email: string
	interfaceId: string
}

type ParsedToken = Claims & {
	iat: number
	exp: number
}

const API_ENDPOINT: string | undefined = import.meta.env.PROD
	? window.env.API_ENDPOINT
	: import.meta.env.VITE_API_ENDPOINT?.toString()

class Auth {
	_callbacks: { id: number; cb: (auth: Auth) => void | Promise<void> }[]
	_cbId: number
	_refreshing = false
	_refreshWaiting: {
		resolve: (value: string | PromiseLike<string | null> | null) => void
		reject: (error: Error) => void
	}[] = []
	expirationTime: Date | null = null
	issuedAt: Date | null = null
	user: Claims | null = null

	constructor() {
		const accessToken = localStorage.getItem(ACCESS_TOKEN)
		if (accessToken) {
			this._updateUserInfo(accessToken)
		}
		this._callbacks = []
		this._cbId = 0

		window.addEventListener("storage", (event) => this._storageChanged(event))
	}

	async signIn(email: string, password: string) {
		const resp = await fetch(`${API_ENDPOINT}/login`, {
			...this._defaultPostRequest(),
			body: JSON.stringify({ email, password }),
		})

		if (resp.status === 429) {
			throw new Error("Too many requests")
		}

		if (resp.status !== 200) {
			const text = await resp.text()
			throw new Error(text)
		}
		const { refreshToken, accessToken } = await resp.json()
		localStorage.setItem(REFRESH_TOKEN, refreshToken)
		localStorage.setItem(ACCESS_TOKEN, accessToken)
		this._updateUserInfo(accessToken)
		await this._authStatusChanged()
		return this.user
	}

	async signOut() {
		const accessToken = await this.getToken()
		try {
			await fetch(`${API_ENDPOINT}/logout`, {
				...this._defaultPostRequest(accessToken),
			})
		} catch (e) {
			console.error(e)
		} finally {
			// in any case, delete the locally stored tokens
			localStorage.removeItem(REFRESH_TOKEN)
			localStorage.removeItem(ACCESS_TOKEN)
			this._updateUserInfo(null)
			await this._authStatusChanged()
		}
	}

	async changePassword(oldPassword: string, newPassword: string) {
		const resp = await fetch(`${API_ENDPOINT}/change-password`, {
			...this._defaultPostRequest(), // no access token needed to change password (we are sending the current password)
			body: JSON.stringify({ email: this.user?.email, oldPassword, newPassword }),
		})
		if (resp.status !== 200) {
			const text = await resp.text()
			throw new Error(text)
		}
	}

	async deleteAccount() {
		const accessToken = await this.getToken()
		const resp = await fetch(`${API_ENDPOINT}/delete-account`, {
			method: "DELETE",
			headers: { Authorization: `Bearer ${accessToken}` },
		})
		if (resp.status !== 200) {
			const text = await resp.text()
			throw new Error(text)
		}
		// delete the locally stored tokens
		localStorage.removeItem(REFRESH_TOKEN)
		localStorage.removeItem(ACCESS_TOKEN)
		this._updateUserInfo(null)
		await this._authStatusChanged()
	}

	async getToken(): Promise<string | null> {
		const accessToken = localStorage.getItem(ACCESS_TOKEN)
		if (this.expirationTime && this.expirationTime > new Date()) {
			return accessToken
		}

		// token no longer valid
		try {
			return await this.refreshToken()
		} catch (e) {
			// this should not happen, but just in case, return the current access token
			if (e instanceof Error && e.message.includes("access token still valid")) {
				return accessToken
			}
			return null
		}
	}

	_waitForToken() {
		return new Promise<string | null>((resolve, reject) => this._refreshWaiting.push({ resolve, reject }))
	}

	_resolveWaitForToken(accessToken: string | null) {
		this._refreshWaiting.forEach(({ resolve }) => resolve(accessToken))
		this._refreshWaiting = []
	}

	_rejectWaitForToken(error: Error) {
		this._refreshWaiting.forEach(({ reject }) => reject(error))
		this._refreshWaiting = []
	}

	async refreshToken() {
		console.log("refreshing token")
		if (this._refreshing) {
			return await this._waitForToken()
		}

		this._refreshing = true
		const oldAccessToken = localStorage.getItem(ACCESS_TOKEN)
		const refreshToken = localStorage.getItem(REFRESH_TOKEN)
		let resp: Response
		try {
			resp = await fetch(`${API_ENDPOINT}/refresh`, {
				...this._defaultPostRequest(oldAccessToken),
				body: JSON.stringify({ refreshToken }),
			})
		} catch (e) {
			if (e instanceof Error) {
				this._rejectWaitForToken(e)
			} else {
				this._rejectWaitForToken(new Error("Unexpected error while fetching new access token"))
			}
			throw e
		} finally {
			this._refreshing = false
		}

		if (resp.status === 401) {
			console.log("refresh token expired, sign out")
			localStorage.removeItem(REFRESH_TOKEN)
			localStorage.removeItem(ACCESS_TOKEN)
			this._updateUserInfo(null)
			this._authStatusChanged()
			this._resolveWaitForToken(null)
			return null
		}

		if (resp.status !== 200) {
			const text = await resp.text()
			const error = new Error(text)
			this._rejectWaitForToken(error)
			throw error
		}

		const { accessToken }: { accessToken: string } = await resp.json()
		localStorage.setItem(ACCESS_TOKEN, accessToken)
		this._updateUserInfo(accessToken)
		this._resolveWaitForToken(accessToken)

		return accessToken
	}

	async activateUser(code: string, user: NewUser) {
		const resp = await fetch(`${API_ENDPOINT}/activate-user`, {
			...this._defaultPostRequest(),
			body: JSON.stringify({ ...user, code }),
		})
		if (resp.status !== 200) {
			const text = await resp.text()
			throw new Error(text)
		}
		const data = await resp.json()
		return data
	}

	async resetPassword(code: string, reset: ResetPassword) {
		const resp = await fetch(`${API_ENDPOINT}/reset-password`, {
			...this._defaultPostRequest(),
			body: JSON.stringify({ ...reset, code }),
		})
		if (resp.status !== 200) {
			const text = await resp.text()
			throw new Error(text)
		}
		const data = await resp.json()
		return data
	}

	async forgotPassword(input: ForgotPassword) {
		const resp = await fetch(`${API_ENDPOINT}/request-password-reset`, {
			...this._defaultPostRequest(),
			body: JSON.stringify(input),
		})
		if (resp.status !== 200) {
			const text = await resp.text()
			throw new Error(text)
		}
		const data = await resp.json()
		return data
	}

	async requestAccount(input: RequestAccount) {
		const resp = await fetch(`${API_ENDPOINT}/request-activation-link`, {
			...this._defaultPostRequest(),
			body: JSON.stringify(input),
		})
		if (resp.status !== 200) {
			const text = await resp.text()
			throw new Error(text)
		}
		const data = await resp.json()
		return data
	}

	async fetchAsset(url: string): Promise<Blob> {
		const accessToken = await this.getToken()
		const resp = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		if (resp.status !== 200) {
			throw new Error("Could not fetch asset.")
		}
		return await resp.blob()
	}

	onAuthStatusChanged = (cb: (auth: Auth) => void | Promise<void>) => {
		const id = this._cbId++
		this._callbacks.push({ id, cb })
		return () => {
			this._callbacks = this._callbacks.filter((c) => c.id !== id)
		}
	}

	_updateUserInfo(accessToken: string | null) {
		const info = this._parseToken(accessToken)
		if (!info) {
			this.user = null
			this.expirationTime = null
			this.issuedAt = null
		} else {
			const { exp, iat, ...user } = info
			this.user = user
			this.expirationTime = exp ? new Date(exp * 1000) : null
			this.issuedAt = iat ? new Date(iat * 1000) : null
		}
	}

	_parseToken(accessToken: string | null): ParsedToken | null {
		if (!accessToken) return null
		try {
			const infoBase64 = accessToken.split(".")[1]
			const info: ParsedToken = JSON.parse(atob(infoBase64))
			return info
		} catch (e) {
			console.error(`Parsing token failed: ${e}`)
			return null
		}
	}

	_defaultPostRequest(accessToken?: string | null) {
		const headers: Record<string, string> = {
			"Content-Type": "application/json",
		}
		if (accessToken) {
			headers["Authorization"] = `Bearer ${accessToken}`
		}

		return {
			method: "POST",
			headers,
		}
	}

	async _authStatusChanged() {
		await Promise.all(this._callbacks.map(({ cb }) => cb(this)))
	}

	async _storageChanged(event: StorageEvent) {
		if (event.key === ACCESS_TOKEN) {
			this._updateUserInfo(event.newValue)
			await this._authStatusChanged()
		}
	}
}

export default Auth

import { Stack, CircularProgress, Typography } from "@mui/material"
import HeatPumpInfoDisplay from "components/HeatPumpInfoDisplay"
import PageWithStatusBar from "components/PageWithStatusBar"
import { ReadHeatPumpGroupSettingsDocument, Role, useReadHeatPumpGroupSettingsQuery } from "generated/graphql"
import useSize from "hooks/useSize"
import { useParams } from "react-router-dom"
import GroupManagementCard from "./components/GroupManagmentCard"
import EditableGroupInterfaceName from "components/EditableGroupInterfaceName"
import NotFoundPage from "pages/NotFoundPage"
import useHasRole from "hooks/useHasRole"
import EditableInterfaceName from "components/EditableInterfaceName"
import { useTranslation } from "react-i18next"

export default function HeatPumpManagementPage() {
	const { t } = useTranslation()
	const { interfaceId } = useParams<{ interfaceId: string }>()
	const isInternal = useHasRole([Role.Admin, Role.Mechanic])
	const smallScreen = useSize("down", "md")
	const { data, loading, error } = useReadHeatPumpGroupSettingsQuery({
		variables: { interfaceId: interfaceId ?? "", isInternal },
		skip: !interfaceId,
	})

	if (!interfaceId) return <NotFoundPage />
	return (
		<PageWithStatusBar
			noPadding={smallScreen}
			navigationName="management"
			statusFromNetwork
			interfaceId={interfaceId ?? ""}
		>
			<HeatPumpInfoDisplay
				titleCard={
					<Stack direction="row" justifyContent="space-between">
						<Typography variant="h4">
							{isInternal ? (
								<EditableInterfaceName shouldRender={true} interfaceId={interfaceId} prefix={`${t("ManagementOf")} `} />
							) : (
								<>
									{t("ManagementOf")}{" "}
									<EditableGroupInterfaceName
										interface={data?.interface ?? { name: null, id: interfaceId }}
										groupId={data?.me?.group?.id ?? ""}
										hideEdit={loading || !data?.me.group?.id || !data.me.isGroupAdmin}
									/>
								</>
							)}
						</Typography>
						{(data || error) && loading && <CircularProgress size={30} />}
					</Stack>
				}
				error={error}
				isLoading={loading || !data?.interface}
				interfaceId={interfaceId}
				fetchOnClaim={ReadHeatPumpGroupSettingsDocument}
			>
				{data?.interface && <GroupManagementCard queryData={data} />}
			</HeatPumpInfoDisplay>
		</PageWithStatusBar>
	)
}

import AppBar from "components/AppBar"
import { styled } from "@mui/material/styles"
import SideMenu from "components/SideMenu"
import { Alert, AlertColor, Box } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Markdown from "markdown-to-jsx"
import { formatMarkDown } from "components/forms/UpsertNotifications"
import {
	Language,
	NotificationType,
	useMarkNotificationReadMutation,
	useUserNotificationsQuery,
} from "generated/graphql"
import { pickBestLanguage } from "tools/translation"

interface Props {
	noPadding: boolean | undefined
}

const StyledMain = styled("main")<Props>(({ theme, noPadding }) => {
	return {
		display: "flex",
		flexFlow: "column",
		flexGrow: 1,
		padding: noPadding ? 0 : theme.spacing(2),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginTop: "64px",
			height: `calc(100vh - 64px)`,
		},
		[theme.breakpoints.down("sm")]: {
			marginTop: "56px",
			height: `calc(100vh - 56px)`,
		},
	}
})

const StyledContent = styled("div")`
	flex: 1 1 auto;
`

export type PageProps = {
	title: React.ReactNode
	middle?: React.ReactNode
	hideTitle?: boolean
	backButton?: boolean
	hideSideMenu?: boolean
	children: React.ReactNode
	noPadding?: boolean
	titleBarColor?: string | null
	handleUseRef?: (data: HTMLElement) => void
}

export default function Page({
	title,
	middle,
	backButton,
	children,
	hideTitle,
	hideSideMenu,
	noPadding,
	titleBarColor,
	handleUseRef,
}: PageProps) {
	const [menuOpen, setMenuOpen] = useState(false)
	const { i18n } = useTranslation("general")

	const { data } = useUserNotificationsQuery({
		variables: { types: NotificationType.Banner, limit: 1 },
	})

	const [markRead] = useMarkNotificationReadMutation()

	const handleMarkBannerRead = async (notificationId: string) => {
		try {
			await markRead({ variables: { notificationId } })
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<Box sx={{ display: "flex" }}>
			<AppBar
				title={title}
				hideTitle={hideTitle}
				backButton={backButton}
				middle={middle}
				color={titleBarColor}
				onClickMenu={() => setMenuOpen((open) => !open)}
			/>
			{!hideSideMenu && (
				<nav style={{ flexShrink: 0, display: "flex", flexDirection: "column" }}>
					<SideMenu open={menuOpen} onClose={() => setMenuOpen(false)} />
				</nav>
			)}
			<StyledMain noPadding={noPadding} ref={handleUseRef}>
				{data && data.notifications.length > 0 && !data?.notifications?.[0]?.read && (
					<Alert
						id="banner"
						sx={{ margin: noPadding ? 0 : -2, mb: noPadding ? 0 : 2 }}
						severity={data.notifications[0].severity as AlertColor}
						onClose={() => handleMarkBannerRead(data.notifications[0].id)}
					>
						{data.notifications[0].content && (
							<Markdown>
								{formatMarkDown(pickBestLanguage(i18n.language as Language, data.notifications[0].content ?? "") ?? "")}
							</Markdown>
						)}
					</Alert>
				)}
				<StyledContent>{children}</StyledContent>
			</StyledMain>
		</Box>
	)
}
